/* src/pages/CategoryIconsPage.css */
.category-icons-page {
    text-align: center;
    padding: 20px;
  }
  
  .category-icons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .category-link {
    text-align: center;
    text-decoration: none;
    color: #333;
  }
  
  .category-icon {
    width: 100px !important;
    height: 100px !important;
    object-fit: contain;
  }
  
  .category-link:hover .category-icon {
    transform: scale(1.1);
    transition: transform 0.2s;
  }
  
  .category-icon-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s;
  }

  .category-icon-card:hover {
    transform: scale(1.05);
  }

  .category-icon-card p {
    margin-top: 10px;
    font-size: 1.1em;
    font-weight: bold;
  }

