/* src/styles.css */

body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  header, footer {
    background-color: #333;
    color: #fff;
    padding: 1em;
  }
  
  header h1 {
    display: inline-block;
    margin: 0;
  }
  
  nav ul {
    list-style: none;
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  
  nav li {
    display: inline-block;
    margin-left: 1em;
  }
  
  nav a {
    color: #fff;
    text-decoration: none;
  }
  
  .content {
    padding: 1em;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1em;
  }
  
  table, th, td {
    border: 1px solid #ddd;
  }
  
  th, td {
    padding: 0.5em;
    text-align: left;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  form label {
    margin-top: 0.5em;
  }
  
  form input, form textarea {
    padding: 0.5em;
    font-size: 1em;
  }
  
  button {
    margin-top: 1em;
    padding: 0.5em;
    font-size: 1em;
  }
  


  @media (max-width: 900px) {
 
    .content {
      padding: 0;
      width: 100%;

    }

  
  }
  