/* src/components/Illustration.css */
.illustration-container {
  width: 100%;
  height: 100%; /* Remplit toute la hauteur disponible */
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  flex: 1; /* Prend tout l'espace disponible dans la colonne */
}

.illustration-container canvas {
  width: 100% !important;
  height: 100% !important; /* Remplit le conteneur */
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}
  
  .stage-wrapper {
    width: 100%;
    height: auto;
    max-width: 100%;
    overflow: hidden;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: auto;
  }
  