/* Responsivity */
@media (max-width: 850px) {
  /* Reset global */
  *, *::before, *::after {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
  }

  html, body {
      width: 100%;
      margin: 0;
      padding: 0;
      overflow-x: hidden;
  }

  body {
      font-family: Arial, sans-serif;
      line-height: 1.4;
      width: 100%;
      overflow-x: hidden;
  }

  /* Main Container */
  .showcase-block {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      overflow-x: hidden;
  }

  /* Header */
  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px 4px !important; /* Réduire le padding vertical */
    background-color: transparent;
    margin-bottom: 0 !important; /* Maintenir un espace en dessous de l'en-tête */
    margin-top: 0 !important; /* Maintenir un espace en dessous de l'en-tête */
    height: auto; /* S'assure que la hauteur du conteneur est ajustée */
  }



  .block-title {
      font-size: 28px;
      font-weight: bold;
      color: #000000;
      margin: 0;
      padding: 0;
      flex: 1;
  }

  .icons-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1px !important; /* Close gap between icons */
      padding-right: 0 !important; /* Supprimer le padding à droite */
      margin: 0;
      padding: 0;
  }

  .icons-container .icon {
      padding: 0;
      margin: 0;
      width: 24px; /* Smaller size for icons */
      height: 24px;
  }

  .icons-container .icon img {
      width: 24px !important;
      height: 24px !important;
      object-fit: contain;
      margin: 0 !important; /* Supprime toute marge */
      padding: 0 !important; /* Supprime tout padding */
      min-height: 24px !important;
  }

  .icons-container .category-icon {
      padding: 0;
      margin: 0;
      width: 24px; /* Taille uniforme */
      height: 24px;
  }

  .icons-container .category-icon img {
      width: 100%; /* Remplir le conteneur */
      height: 100%;
      object-fit: contain;
      margin: 0;
      padding: 0;
      display: block; /* Éliminer les espaces supplémentaires */
  }

  button.icon.category-icon {
      width: 24px !important; /* Ajustez la taille si nécessaire */
      height: 24px !important; /* Ajustez selon la taille de votre icône */
      padding: 0; /* Retirez tout padding interne */
      margin: 0; /* Retirez toute marge */
      background: none; /* Retirez l'arrière-plan si applicable */
      border: none; /* Supprimez les bordures si présentes */
      display: flex; /* Assurez-vous que l'icône à l'intérieur est bien centrée */
      align-items: center; /* Centre l'icône verticalement */
      justify-content: center; /* Centre l'icône horizontalement */
  }

  .illustration-container {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }


  /* Content Container */
  .content-container {
      display: flex;
      flex-direction: column;
      width: 100%; /* Remplacer 100vw par 100% */
      margin: 0;
      padding: 0;
      overflow-x: hidden;
  }

  .left-column, .right-column {
      width: 100% !important;
      margin: 0;
      padding: 0;
      flex: none;
  }

  .left-column {
      margin-right: 0px;
      margin-bottom: 3px !important; /* Même espace que entre label et contenu */

  }

  .right-column {
      border: none;
      background-color: transparent;
      margin-top: 0 !important;
      padding-top: 0 !important;
  }

  /* Right Content */
  .right-content {
      width: 100%; /* Remplacer 100vw par 100% */
      padding: 0 !important; /* Remove extra padding */
      margin: 0;
  }

  .zone-content {
      width: 100%; /* Remplacer 100vw par 100% */
      margin: 0;
      padding: 0;
  }

  .zone-label {
      font-size: 1.4em ;
      text-align: center;
      width: 100%;
      margin-bottom: 0 px;
      width: 100%; /* Remplacer 100vw par 100% */
  }

  /* Play/Pause Section */
  .play-pause-text-container {
      display: flex;
      align-items: flex-start; /* Alignement en haut */
      gap: 5px;
      margin-bottom: 0px;
      width: 100%;
      padding: 0;
  }


  /* Text Content */
  .text-content {
      font-size: 16px;
      color: #000000;
      text-align: left; /* Centrer le texte */
      width: 100%; /* Remplacer 100vw par 100% */
      cursor: pointer; /* Change le curseur au survol */
      outline: none; /* Supprime le contour par défaut */
      padding: 0 !important;
  }

/* Styles pour le focus (accessibilité) */
.text-content:focus {
  outline: 2px solid #007BFF; /* Ajoute un contour bleu lors du focus */
  border-radius: 4px;
}

/* Changer la couleur du texte au survol */
.text-content:hover {
  color: #1f1f1f; /* Bleu au survol */
  outline: none; /* Supprime le contour par défaut */

}


  /* Images */
  .zone-image {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 5px 0;
  }

  .zone-image img {
      max-width: 50% !important;
      height: auto;
      border-radius: 8px;
  }

  .zone-image img.clickable-image:hover {
      transform: none;
  }

  /* Audio/Video */
  .text-audio-container {
      width: 100%;
      margin: 5px 0;
  }

  .text-audio-container button.play-pause-button {
      margin-right: 5px;
  }

  .text-audio-container audio {
      width: 100%; /* Remplacer 100vw par 100% */
  }

  /* Description Section */
  .description-content {
      width: 100%; /* Remplacer 100vw par 100% */
      margin: 0;
      padding: 0;
  }

  .description-content h2 {
      font-size: 13px;
      margin-bottom: 5px;
  }

  .description-content p {
      font-size: 14px;
  }

  /* Portrait Image */
  .portrait-image {
      width: 100%;
      margin: 10px 0;
  }

  .portrait-image img {
      max-width: 100%;
      height: auto;
      border-radius: 8px;
  }

  /* Description Audio */
  .description-audio {
      width: 100%; /* Remplacer 100vw par 100% */
      margin: 5px 0;
  }

  .description-audio audio {
      width: 100%; /* Remplacer 100vw par 100% */
  }

  /* Music Player */
  .SongPlayer {
      width: 100%; /* Remplacer 100vw par 100% */
      padding: 0px 0;
      margin: 10px 0;
  }

  /* Hide unused elements */
  .zone-video, .zone-audio {
      display: none;
  }

  /* Ensure full-width divs */
  div {
      max-width: 100% !important; /* Remplacer 100vw par 100% */
      margin: 0 !important;
      overflow-x: hidden;
  }

  /* Full width for direct children of content container */
  .div.content-container > * {
      width: 100% !important; /* Remplacer 100vw par 100% */
      margin: 0 !important;
      padding: 0 !important;
      overflow-x: hidden;
  }

  .zone-application {
    width: 80%;
    padding: 0;
    margin: 0;
  }

  .zone-application > div {
    padding: 2px !important;
  }

  /* Réduire la taille des boutons sur mobile */
  .zone-application button {
    font-size: 14px;
    padding: 6px 12px;
  }

  /* Assurer que le plateau d'échecs ne déborde pas */
  .zone-application .aspect-square {
    max-width: 100%;
    margin: 0 auto;
  }

  .label-container {
    padding: 5px 10px !important; /* Réduit le padding vertical à 5px, garde 10px horizontal */
    margin-top: 3px !important; /* Espace après l'illustration */
    margin-bottom: 3px !important; /* Espace de 3px avec la zone de contenu */
    width: 100%;
  }

/* Ajustement pour le label lui-même pour compenser le padding réduit */
.zone-label {
    font-size: 1.4em;
    text-align: center;
    width: 100%;
    margin: 0;
    line-height: 1.2; /* Ajuste la hauteur de ligne pour le texte */
  }

 /* Ajustement pour la zone de contenu */
 .content-container-box {
    width: 100%;
    margin-top: 3px !important; /* Assure l'espace depuis le label */
  }


}


@media (max-width: 500px) {
    .block-title {
      font-size: 20px;
    }

/* Text Content */
.text-content {
    font-size: 13px;
    color: #000000;
    text-align: left; /* Centrer le texte */
    width: 100%; /* Remplacer 100vw par 100% */
    cursor: pointer; /* Change le curseur au survol */
    outline: none; /* Supprime le contour par défaut */
    padding: 0 !important;
}

/* Réduire encore plus le padding pour les très petits écrans */
.label-container {
    padding: 3px 8px !important;
  }

  .left-column {
    margin-right: 0px;
    margin-bottom: 2px !important; /* Même espace que entre label et contenu */

}


.label-container {
    padding: 2px 10px !important; /* Réduit le padding vertical à 5px, garde 10px horizontal */
    margin-top: 2px !important; /* Espace après l'illustration */
    margin-bottom: 2px !important; /* Espace de 3px avec la zone de contenu */
    width: 100%;
  }
  }