.dj-container {
  background: #1a1a1a;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.dj-loading {
  color: white;
  text-align: center;
  padding: 1rem;
}

.turntables-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.turntables-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
}

.turntable-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.volume-indicators-row {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 0.5rem 0;
}

.bpm-display {
  color: #00ff00;
  font-family: 'Digital', monospace;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
  background: #000;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #333;
}

.controls-left,
.controls-right {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
}

.controls-left {
  left: -1rem;
}

.controls-right {
  right: -1rem;
}

.bpm-slider-vertical {
  appearance: none;
  height: 100px;
  width: 2px;
  background: #333;
  outline: none;
  transform: rotate(-90deg);
  cursor: pointer;
}

.bpm-slider-vertical::-webkit-slider-thumb {
  appearance: none;
  width: 10px;
  height: 20px;
  background: #666;
  border-radius: 2px;
  cursor: pointer;
}

.crossfader-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
}

.crossfader {
  appearance: none;
  width: 200px;
  height: 2px;
  background: #333;
  outline: none;
  border-radius: 1px;
  cursor: pointer;
  margin-top: 1rem;
}

.crossfader::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 10px;
  background: #666;
  border-radius: 2px;
  cursor: pointer;
}

@media (max-width: 480px) {
  .turntables-row {
    flex-direction: column;
    align-items: center;
  }
  
  .volume-indicators-row {
    flex-direction: row;
    margin: 1rem 0;
  }
  
  .controls-left,
  .controls-right {
    position: relative;
    left: auto;
    right: auto;
    margin: 0.5rem 0;
  }
  
  .bpm-slider-vertical {
    transform: none;
    width: 100px;
    height: 2px;
  }
  
  .crossfader {
    width: 150px;
  }
}