/* src/pages/EditBlock.css */

.edit-block {
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  height: auto !important;
  font-family: Arial, sans-serif;
}

.edit-block h2 {
  text-align: center;
  margin-bottom: 20px;
}


.form-section {
  margin-bottom: 30px;
}

.form-section h3 {
  margin-bottom: 10px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group .required {
  color: red;
  margin-left: 5px;
}

.form-group input[type="text"],
.form-group input[type="file"],
.form-group select,
.form-group textarea {
  padding: 8px;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.form-group textarea {
  resize: vertical;
  min-height: 100px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  vertical-align: middle;
}


.duplicate-button {
  padding: 8px 16px;
  background-color: #28a745; /* Vert pour indiquer une action positive */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.duplicate-button:hover {
  background-color: #218838;
}


.preview-portrait {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
}

.title-audio-preview {
  margin-bottom: 10px;
}

.preview-illustration {
  max-width: 100%;
  max-height: 500px; /* ou une valeur appropriée en fonction de la taille de la page */
  object-fit: contain; /* Contain permet d'afficher l'image sans la tronquer */
  margin-bottom: 10px;
}


.annotate-container {
  position: relative;
}

.submit-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}

.delete-zone-button {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.delete-zone-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.delete-zone-button:hover:not(:disabled) {
  background-color: #ff1a1a;
}

/* Styles pour le formulaire de modification de zone */
.zone-edit-form {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
} 

/* Styles spécifiques pour TitleAudio */
.title-audio-preview audio {
  width: 100%;
  border-radius: 4px;
  outline: none;
  border: none;
  box-shadow: none;
}

/* Styles spécifiques pour BlockNumber */
.form-section h3 + .form-group input[type="text"] {
  width: 200px;
}

.visibility-toggle {
  margin-top: 1rem;
}

.toggle-label {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.toggle-button {
  padding: 0.5rem 1rem;
  border: 2px solid #ccc;
  border-radius: 20px;
  background-color: #f5f5f5;
  cursor: pointer;
  transition: all 0.3s ease;
}

.toggle-button.active {
  background-color: #4CAF50;
  color: white;
  border-color: #45a049;
}


.color-picker-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.color-picker {
  width: 50px;
  height: 50px;
  padding: 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.color-input {
  width: 100px;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}


/* Responsive Design */
@media (max-width: 768px) {
  .edit-block {
    padding: 10px;
  }

  .form-section h3 {
    font-size: 1.2em;
  }

  .form-group input[type="text"],
  .form-group input[type="file"],
  .form-group select,
  .form-group textarea {
    padding: 6px;
  }

  .submit-button {
    width: 100%;
  }
}
