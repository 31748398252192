/* Layout de base */
.list-blocks {
    padding: 10px;
    max-width: 100vw;
}

/* Titre de la page */
.list-blocks h2 {
    margin-top: 10px;
    text-align: center;
    font-size: 2em;
    cursor: default;
}

/* Barre de recherche */
.list-blocks .search-bar {
    width: 100%;
    max-width: 500px;
    height: 40px;
    padding: 10px;
    margin: 0 auto 20px auto;
    display: block;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 18px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: text;
}

/* Styles communs pour toutes les icônes */
.filter-icon, 
.language-icon,
.category-icon,
.flag {
    width: 60px !important;
    height: 60px !important;
    min-width: 60px !important;
    min-height: 60px !important;
    max-width: 60px !important;
    max-height: 60px !important;
    display: block !important;
    border-radius: 6px !important;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}

/* Style spécifique pour les drapeaux */
.filter-icon[alt^="Drapeau"],
.language-icon,
.flag {
    object-fit: fill !important;
    padding: 0 !important;
    background-color: transparent !important;
}

/* Style pour les icônes de catégories */
.filter-icon:not([alt^="Drapeau"]),
.category-icon {
    object-fit: contain !important;
    padding: 0px !important;
}

/* Container des lignes de filtres */
.filter-row {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin: 10px 0;
    padding: 15px 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    border: 1px solid #ddd;
}

/* États des icônes de filtre */
.filter-icon.active {
    opacity: 1;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filter-icon.active:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.filter-icon.inactive {
    opacity: 0.5;
    background-color: #ddd;
    filter: grayscale(100%);
}

.filter-icon.inactive:hover {
    transform: translateY(-2px);
    opacity: 0.7;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.filters-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
}

/* Media Queries pour les petits écrans */
@media (max-width: 600px) {
    .list-blocks {
        padding: 10px;
        max-width: 100vw;
    }
    
    .list-blocks h2 {
        margin-top: 0px;
        text-align: center;
        font-size: 2em;
        cursor: default;
    }

    .filter-icon, 
    .language-icon,
    .category-icon,
    .flag {
        width: 38px !important;
        height: 38px !important;
        min-width: 38px !important;
        min-height: 38px !important;
        max-width: 38px !important;
        max-height: 38px !important;
    }

    .filter-row {
        padding: 5px 5px;
        gap: 6px;
    }

    .categories-container {
        gap: 5px;
    }

    .filters-section {
        margin-bottom: 10px !important;
    }
}