/* src/components/Header.css */

/* Global header styles */
.header {
  display: flex;
  justify-content: space-between; /* Distribute space between left and right */
  align-items: center; /* Align items vertically in the middle */
  padding: 5px 5px; /* Add some padding around the header */
  background-color: #333; /* Example background color */
  color: white; /* Text color */
  margin-bottom: 0 !important; /* Supprimer l'espace en bas du header */
  position: relative;  /* Pour servir de référence au positionnement absolu */
  z-index: 1;         /* Donne un contexte d'empilement */
}

/* Left section of the header (Bitgrid, List, Add, Categories) */
.header-left {
  display: flex;
  align-items: center; /* Align items vertically */
}

/* Title (Bitgrid) */
.header-title {
  font-size: 28px; /* Adjust font size */
  font-weight: bold;
  margin-right: 20px; /* Space between the title and the nav */
  margin-left: 90px;  /* Ajustez cette valeur en fonction de la taille de votre image */
  color: white; /* Ensure title is white */
  text-decoration: none; /* Remove underline */
  transition: color 0.3s; /* Add transition for color change */
  line-height: 1; /* Empêche le texte d'ajouter de l'espace supplémentaire */
}

.header-title:hover {
  color: #8e97a0; /* Change color on hover */
}

.header-title:active {
  color: #b9c5d1; /* Change color when clicked */
}

/* Navigation links (List, Add, Categories) */
.header-nav {
  display: flex;
  list-style: none; /* Remove bullets from list */
  margin: 0;
  padding: 0;
}

.header-nav li {
  margin-right: 20px; /* Space between links */
}

.header-nav li a {
  text-decoration: none;
  color: white;
  font-size: 18px; /* Adjust font size */
}

.header-nav li a:hover {
  text-decoration: underline;
}

.header-nav li.active a {
  font-weight: bold; /* Make active link bold */
}

/* Right section of the header (icons) */
.header-right {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between the icons */
}

.auth-buttons {
  margin-left: 1rem;
}

.auth-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  text-decoration: none;
  font-size: 0.9rem;
  transition: background-color 0.3s;
}

.auth-button:hover {
  background-color: #0056b3;
}

.auth-button:active {
  background-color: #004494;
}

/* Style pour le bouton de déconnexion */
button.auth-button {
  background-color: #dc3545;
}

button.auth-button:hover {
  background-color: #c82333;
}

/* Action buttons (Edit, Delete) */
.header-action-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex; /* Ensure the button is a flex container */
  align-items: center; /* Align items vertically within the button */
  margin-top: 0;
}

/* Ensure icon size consistency and remove vertical alignment */
.header-action-icon {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

/* Set consistent height for the header links */
.header-nav li a {
  line-height: 30px; /* Ensure the text height matches the icon height */
}

.logo-title-container {
  display: flex;
  align-items: center; /* Change flex-start to center pour alignement vertical */
  gap: 10px;
  position: relative;  /* Pour le z-index */
  z-index: 2;         /* Pour être sûr que le conteneur reste au-dessus */
  padding: 0; /* Retirer le padding pour les grands écrans */
  overflow: visible !important;
  height: 48px; /* Définir une hauteur fixe égale à la hauteur du logo */
}

.logo-link {
  position: relative;
  width: 48px; /* Largeur fixe pour maintenir l'espace */
  height: 48px; /* Hauteur fixe pour maintenir l'espace */
  overflow: visible !important; /* Permet le débordement */
}

.header-logo {
  height: 48px;
  width: auto;
  position: absolute;
  top: 2px;
  left: 30px;
  z-index: 100;
  transform-origin: top left;
  transform: scale(1.5);
  transition: all 0.3s ease;
  filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.45));
}

/* Updated hover effect to enhance existing shadow */
.header-logo:hover {
  transform: scale(1.7) ;
  filter: drop-shadow(6px 6px 10px rgba(0, 0, 0, 0.55)) brightness(1.1);
  cursor: pointer;
}


/* Effet de brillance constant */
.header-logo::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent 0%,
    rgba(255, 255, 255, 0.1) 45%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.1) 55%,
    transparent 100%
  );
  transform: rotate(45deg);
  pointer-events: none; /* Pour que l'effet ne bloque pas les interactions */
  z-index: 101;
}


/* Animation pulse subtile */
@keyframes subtle-pulse {
  0% { 
    transform: scale(1.2);
    filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.45));
  }
  50% { 
    transform: scale(1.25);
    filter: drop-shadow(5px 5px 9px rgba(0, 0, 0, 0.5));
  }
  100% { 
    transform: scale(1.2);
    filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.45));
  }
}

@media (max-width: 900px) {
  
  .header {
    padding: 5px 5px;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }


  .header-title {
    font-size: 24px;
    margin-right: 5px;
    margin-left: 35px;

  }


  .header-nav li {
    margin-right: 0px; /* Space between links */
  }

  .header-nav li a {
    text-decoration: none;
    color: white;
    font-size: 14px; /* Adjust font size */
  }

  .logo-title-container {
    height: 40px; /* Ajuster la hauteur pour les écrans moyens */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
  }

  .logo-link {
    width: 40px;
    height: 40px;
  }

  .header-logo {
    height: 40px;        /* Image plus grande */
    top: 2px;          /* Ajustez cette valeur pour positionner l'image verticalement */
    transform: scale(1.3);  /* Rend l'image 50% plus grande */
    left: 0; /* Retour à la position initiale pour les écrans moyens */

  }

 
.logo-title-container {
  overflow: visible !important;
}

.header-left {
  overflow: visible !important;
}

.header {
  overflow: visible !important;
}

}


@media (max-width: 600px) {
  .logo-title-container {
    height: 30px; /* Ajuster la hauteur pour les petits écrans */
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
  }

  .logo-link {
    width: 30px;
    height: 30px;
  }
  

  .header-title {
    font-size: 20px; /* Adjust font size */
    margin-left: 25px;  /* Ajustez cette valeur en fonction de la taille de votre image */
  }

  .header-logo {
    height: 30px;        /* Image plus grande */
    top: 2px; /* Maintenir la même valeur pour la cohérence */
    left: 0;
    transform: scale(1.2);  /* Rend l'image 50% plus grande */
    animation: none; /* Désactiver l'animation par défaut */
  }

/* Hover spécifique pour les petits écrans */
.header-logo:hover {
  transform: scale(1.3); /* Scale plus petit pour les petits écrans */
  animation: none; /* S'assurer qu'aucune animation ne persiste */
}

/* Style après le hover (pour forcer le reset) */
.header-logo:not(:hover) {
  transform: scale(1.2);
  transition: transform 0.3s ease;
}

/* Pour s'assurer que le conteneur parent permet aussi le débordement */
.logo-title-container {
  overflow: visible !important;
}

.header-left {
  overflow: visible !important;
}

.header {
  overflow: visible !important;
}

}