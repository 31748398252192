/* src/pages/ShowcaseBlock.css */

/* Réinitialisation globale */
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Assurez-vous que html, body, root prennent toute la hauteur */
html, body, #root {
  height: 100%;
  margin: 0; /* Ajoutez cette ligne explicitement */
  padding: 0; /* Ajoutez cette ligne explicitement */
  overflow: auto; /* Supprimer tout débordement */
}

/* Style du corps */
body {
  font-family: Arial, sans-serif;
}

/* Showcase Block */
.showcase-block {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 0 !important; /* Force la suppression de toute marge */
  padding-top: 0 !important; /* Force la suppression de tout padding */

}

/* Header Container */
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px; /* Ajustement du padding pour plus d'espace horizontal */
  background-color: transparent;
  margin-bottom: 0px !important; /* Maintenir un espace en dessous de l'en-tête */
  margin-top: 0 !important; /* Maintenir un espace en dessous de l'en-tête */
  height: auto; /* S'assure que la hauteur du conteneur est ajustée */
  width: 100%; /* Assurez-vous que le conteneur prend toute la largeur */
  max-width: 100%; /* Empêcher tout débordement */
}

/* Block Title */
.block-title {
  font-size: 48px;
  font-weight: bold;
  color: #000000;
  line-height: 1;
  margin: 0 !important;
  padding: 0 !important; /* Réduire l'espace autour du titre */
}

.block-title.clickable-title {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  outline: none !important; /* Supprime le contour par défaut */
}

.block-title.clickable-title:hover {
  color: #1f1f1f; /* Bleu au survol */
  outline: none !important; /* Supprime le contour par défaut */
}

.block-title.clickable-title:focus {
  outline: none !important; /* Supprime le contour par défaut */
  border-radius: 4px;
}




/* Style pour les éléments audio cachés */
.block-title audio {
  display: none; /* Cacher l'élément audio visuellement */
}


/* Icons Container */
.icons-container {
  display: inline-flex; /* Utiliser inline-flex pour aligner les icônes horizontalement */
  align-items: center; /* Centre verticalement les icônes */
  justify-content: flex-end; /* Aligne les icônes à droite */
  gap: 0px; /* Augmenter légèrement l'espacement entre les icônes */
  margin: 0 !important;
  padding: 0 !important;
  padding-right: 0 !important; /* Supprimer le padding à droite */
  line-height: 1 !important; /* Ajuster la hauteur de ligne pour éviter un espace vertical */
  height: auto; /* S'assure que la hauteur du conteneur est ajustée */
  overflow: hidden; /* Supprimer tout débordement */
}

/* Style général pour les icônes */
.icons-container .icon {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex; /* Assure que l'icône occupe tout l'espace sans marge */
  align-items: center;
  justify-content: center;
  height: auto; /* Pour ajuster la hauteur */
  line-height: 1 !important; /* Fixer une hauteur de ligne égale à la hauteur de l'icône */
}

/* Images dans les icônes */
.icons-container .icon img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  display: block; /* Supprimer tout espace dû à l'affichage inline des images */
  margin: 0; /* Supprimer toute marge sur l'image */
  vertical-align: middle; /* Supprimer l'espace ajouté par l'image inline */
}


button.icon.category-icon {
  width: 40px !important; /* Ajustez la taille si nécessaire */
  height: 40px !important; /* Ajustez selon la taille de votre icône */
  padding: 0; /* Retirez tout padding interne */
  margin: 0; /* Retirez toute marge */
  background: none; /* Retirez l'arrière-plan si applicable */
  border: none; /* Supprimez les bordures si présentes */
  display: flex; /* Assurez-vous que l'icône à l'intérieur est bien centrée */
  align-items: center; /* Centre l'icône verticalement */
  justify-content: center; /* Centre l'icône horizontalement */
}


/* Content Container */
.content-container {
  display: flex;
  flex: 1;
  overflow: hidden;
  padding: 0 2px;
}

/* Colonnes */
.left-column {
  flex: 66; /* La colonne de gauche prend 65 parts */
  margin-right: 12px; /* Espace de 16px entre les colonnes */
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.right-column {
  flex: 34; /* La colonne de droite prend 35 parts */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: transparent;

}

/* Contenu de droite */
.right-content {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.label-container {
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box; /* Assure que le padding est inclus dans la largeur */
}

.content-container-box {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Contenu de la zone sélectionnée */
.zone-content {
  display: flex;
  flex-direction: column;
}

/* Style pour les langues RTL */
.zone-content[dir="rtl"] {
  text-align: right;
}

.zone-content[dir="rtl"] .text-content {
  text-align: right;
}

.zone-content[dir="rtl"] .zone-label {
  text-align: right;
  width: 100%;
}




/* Label de la zone */
.zone-label {
  font-size: 2em; /* Taille plus grande */
  font-weight: bold; /* En gras */
  margin: 0; /* Retire la marge par défaut */
  padding: 0; /* Retire le padding par défaut */
  outline: none;
  width: 100%; /* Assure que le label prend toute la largeur */
}

.clickable-label {
  cursor: pointer;
  display: block; /* Change de inline-flex à block */
  width: 100%; /* Assure que le label prend toute la largeur */
  align-items: center;
  outline: none !important; /* Supprime le contour par défaut */
  margin-bottom: 0px !important;
}

.clickable-label:hover {
  color: #1f1f1f !important; /* Bleu au survol */
  outline: none !important; /* Supprime le contour par défaut */

}

.clickable-label:focus {
  outline: none !important; /* Contour bleu lors du focus */
  border-radius: 4px;
}


/* Play/Pause et texte */
.play-pause-text-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 0px;
}


/* Text content */
.text-content {
  white-space: pre-wrap;
  font-size: 24px;
  color: #000;
  flex: 1;
  cursor: pointer; /* Change le curseur au survol */
  outline: none; /* Supprime le contour par défaut */
}

/* Styles pour le focus (accessibilité) */
.text-content:focus {
  outline: none; 
  border-radius: 4px;
}

/* Changer la couleur du texte au survol */
.text-content:hover {
  color: #1f1f1f; /* Bleu au survol */
  outline: none; /* Supprime le contour par défaut */

}


/* Image de la zone */
.zone-image {
  display: flex;
  justify-content: center; /* Centrer l'image horizontalement */
  margin-bottom: 20px;
}

.zone-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px; /* Ajout d'un léger arrondi pour l'esthétique */
  transition: transform 0.3s ease; /* Ajout d'une transition pour un effet au survol */
}

/* Effet au survol de l'image cliquable */
.zone-image img.clickable-image:hover {
  transform: scale(1.05); /* Légère augmentation de la taille de l'image */
}

/* Vidéo ou Audio de la zone (caché) */
.zone-video,
.zone-audio {
  display: flex; /* Cacher le lecteur audio/vidéo */
  margin-bottom: 20px;
}

.zone-video-display {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.zone-video-display video {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}





/* Classe pour les images cliquables avec curseur pointeur */
.clickable-image {
  cursor: pointer;
}





/* Styles pour l'audio du texte */
.text-audio-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.text-audio-container button.play-pause-button {
  margin-right: 10px; /* Espace entre le bouton et l'audio */
}

.text-audio-container audio {
  flex: 1;
}

/* Description du Bloc */
.description-content {
  margin-bottom: 20px;
}

.description-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #000000;
}

.description-content p {
  font-size: 16px;
  color: #000000;
}

.portrait-image {
  margin-top: 20px;
}

.portrait-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.description-audio {
  margin-top: 20px;
}

.description-audio audio {
  width: 100%;
  border-radius: 4px;
}

/* Song Player */
.SongPlayer {
  padding: 10px;
  margin-top: 0px;
}

.zone-application {
  margin-top: 20px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


@media (max-width: 1800px) {
  
  .zone-image img {
    max-width: 50%;
    height: auto;
    border-radius: 8px;
}

/* Text content */
.text-content {
  white-space: pre-wrap;
  font-size: 24px;
  color: #000000;
  flex: 1;
}

.zone-label {
  font-size: 2em;
  text-align: center;
  width: 100%;
  margin-bottom: 0;
  width: 100%; /* Remplacer 100vw par 100% */
}

.block-title {
  font-size: 36px;
  font-weight: bold;
  color: #000000;
  line-height: 1;
  margin: 0 !important;
  padding: 0 !important; /* Réduire l'espace autour du titre */
}

.play-pause-text-container {
  display: flex;
  align-items: flex-start; /* Alignement en haut */
  gap: 5px;
  margin-bottom: 0px;
  width: 100%;
  padding: 0;
}

.header-container {
  padding: 2px 5px !important; /* Réduire le padding vertical */
  margin-bottom: 2px !important; /* Réduire la marge en bas */
  margin-top: 2px !important; /* Réduire la marge en haut */
}




}

@media (max-width: 1000px) {
  .block-title {
    font-size: 38px;
  }
}