/* src/pages/LoginPage.css */
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 64px); /* Ajustez selon la hauteur de votre header */
    padding: 2rem;
    background-color: #f5f5f5;
  }
  
  .login-form {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .login-form h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #666;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .error-message {
    color: #dc3545;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .submit-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .submit-button:active {
    background-color: #004494;
  }


  .password-input {
    position: relative;
  }
  
  .password-toggle-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
  }
  
  .password-toggle-button:hover {
    color: #333;
  }