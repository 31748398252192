/* src/components/IllustrationEditor.css */

.illustration-editor {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    margin: 0;
}

.illustration-container {
    display: flex;
    flex-grow: 1;
    gap: 20px;
}

.stage-wrapper {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto; /* Utilisez 'auto' pour permettre le défilement si l'image est trop grande pour l'écran */
    position: relative; /* Assure que les zones sont positionnées par rapport à ce conteneur */
}

.upload-zone-image-button {
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.upload-zone-image-button:hover {
    background-color: #218838;
}

.zone-editor {
    width: 300px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
}

.zone-edit-section {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    background-color: #f9f9f9;
}

.zone-edit-section h3 {
    margin-top: 0;
}

.form-group {
    margin-bottom: 15px;
}

.submit-button,
.cancel-button {
    margin-right: 10px;
}

.zone-editor h3 {
    margin-bottom: 10px;
}

.zone-editor-controls {
    display: flex;
    flex-direction: column;
}

.zone-editor-controls label {
    margin-bottom: 10px;
}

.zone-editor-controls input {
    width: calc(100% - 10px);
    padding: 5px;
    margin-top: 5px;
}

.zone-editor-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.zone-editor-buttons button {
    flex: 1 1 45%;
    padding: 6px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: white;
}

.edit-button {
    background-color: #007bff;
}

.edit-button:hover {
    background-color: #0069d9;
}

.save-button {
    background-color: #28a745;
}

.save-button:hover {
    background-color: #218838;
}

.cancel-button {
    background-color: #6c757d;
}

.cancel-button:hover {
    background-color: #5a6268;
}

.remove-image-button {
    background-color: #dc3545;
}

.remove-image-button:hover {
    background-color: #c82333;
}

.replace-image-button {
    background-color: #17a2b8;
}

.replace-image-button:hover {
    background-color: #138496;
}

.delete-button {
    background-color: #343a40;
}

.delete-button:hover {
    background-color: #23272b;
}

.right-column {
    width: 300px;
    padding: 10px;
    border-left: 1px solid #ccc;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
}

.icons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
}

.icon-image {
    width: 50px;
    height: 50px;
}

.content-area {
    width: 100%;
    margin-top: 20px;
    text-align: center;
}

.description-container {
    padding: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(144, 156, 214, 0.3);
}

.zone-content-container {
    padding: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(166, 134, 218, 0.3);
}

.zone-content-image {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
}

/* Ajouts pour les labels cliquables et les contrôles audio */

.clickable-label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    color: #007BFF; /* Couleur bleue pour indiquer l'interactivité */
    transition: color 0.3s ease;
}

.clickable-label:hover {
    color: #0056b3; /* Bleu plus foncé au survol */
}

.clickable-label:focus {
    outline: 2px solid #0056b3; /* Contour bleu foncé lors du focus */
    border-radius: 4px;
}

.zone-label-audio {
    margin-left: 10px;
}

.zone-label-audio audio {
    width: 100%;
    margin-top: 10px;
}

.preview-audio {
    margin-top: 10px;
}

.preview-audio audio {
    width: 100%;
    outline: none;
}

.audio-controls {
    display: flex;
    align-items: center;
    gap: 10px;
}

.audio-controls button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
}

.audio-controls button img {
    width: 24px;
    height: 24px;
}

.zone-editor .zone-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.zone-editor .zone-content .zone-label {
    font-weight: bold;
    margin-bottom: 5px;
}

.zone-editor .zone-content .zone-label-audio {
    width: 100%;
    margin-top: 5px;
}

.control-button {
    background-color: #17a2b8;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 6px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 5px;
}

.control-button:hover {
    background-color: #138496;
}

.control-button:focus {
    outline: 2px solid #138496;
}

.form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 4px;
  }
  
  .application-preview {
    margin-top: 10px;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 4px;
  }

  .bulk-edit-section {
    margin-top: 2rem;
    padding: 1.5rem;
    border-top: 1px solid #e5e7eb;
    background-color: #f9fafb;
  }
  
  .bulk-edit-section h3 {
    margin-bottom: 1rem;
    font-size: 1.25rem;
    color: #374151;
  }
  
  .csv-controls {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .download-csv,
  .upload-csv {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .csv-button {
    padding: 0.5rem 1rem;
    background-color: #3b82f6;
    color: white;
    border: none;
    border-radius: 0.375rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .csv-button:hover {
    background-color: #2563eb;
  }
  
  .csv-upload-label {
    padding: 0.5rem 1rem;
    background-color: #4b5563;
    color: white;
    border-radius: 0.375rem;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.2s;
  }
  
  .csv-upload-label:hover {
    background-color: #374151;
  }
  
  .csv-upload-input {
    display: none;
  }
  
  .csv-info {
    font-size: 0.875rem;
    color: #6b7280;
    margin-top: 0.25rem;
  }


  .bulk-edit-sections {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .bulk-edit-section {
    background-color: #f8f9fa;
    padding: 1.5rem;
    border-radius: 0.5rem;
    border: 1px solid #dee2e6;
  }
  
  .bulk-edit-section h3 {
    margin-bottom: 1.5rem;
    color: #1a73e8;
  }
  
  .csv-controls {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .upload-files {
    margin-top: 1rem;
  }
  
  .file-upload-label {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: #6c757d;
    color: white;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .file-upload-label:hover {
    background-color: #5a6268;
  }
  
  .file-upload-input {
    display: none;
  }


@media (max-width: 768px) {
    .zone-editor {
        width: 100%;
    }

    .audio-controls {
        flex-direction: column;
        align-items: stretch;
    }

    .audio-controls button {
        width: 100%;
    }

    .zone-label-audio audio {
        width: 100%;
    }
}

