/* src/pages/BlockIconsPage.css */

.block-icons-page {
    padding: 20px;
    text-align: center;
  }
  
  .filters-container {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }
  
  .filter {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .flag-icon {
    width: 30px;
    height: 20px;
    object-fit: contain;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .blocks-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    padding: 10px;
  }
  
  .block-icon-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 5px;
  }
  
  .block-icon-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .block-icon {
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin-bottom: 10px;
  }
  
  .no-icon {
    width: 100px;
    height: 100px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .block-icon-card p {
    font-size: 1em;
    font-weight: bold;
    color: #333;
  }
  